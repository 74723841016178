import {
  Box,
  Button,
  Center,
  Flex,
  Show,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import { WATCH_HOW_IT_WORKS } from '@/constants/analytics';
import { IIndexable } from '@/customtypes/common';
import { StrapiCard, StrapiSectionDetail } from '@/customtypes/global';
import { trackEvent } from '@/dapp-sdk-v2';
import BaseImage from '@/dapp-sdk-v2/components/Image';

import Section from '../Section';

interface HowItWorksProps {
  sectionDetails: StrapiSectionDetail;
  imgKey?: string;
  link?: string;
  worksData?: StrapiCard[];
}

const getWorksIllustration: IIndexable = {
  ethWorks: {
    web: '/assets/images/works/works-web.svg',
    mobile: '/assets/images/works/works-mobile.svg',
  },
  polygonStakeMatic: {
    web: '/assets/images/works/polygon/details/stake.svg',
    mobile: '/assets/images/works/polygon/details/stake.svg',
  },
  polygonReceiveMaticx: {
    web: '/assets/images/works/polygon/details/receive.svg',
    mobile: '/assets/images/works/polygon/details/receive.svg',
  },
  polygonParticipate: {
    web: '/assets/images/works/polygon/details/participate.svg',
    mobile: '/assets/images/works/polygon/details/participate.svg',
  },
};

const How = ({ sectionDetails, imgKey, link, worksData }: HowItWorksProps) => {
  return (
    <Section
      title={sectionDetails.title}
      subTitle={sectionDetails.sub_title}
      id={sectionDetails.section_id}
    >
      {link && (
        <>
          <Show above="lg">
            <Box
              mt="2rem"
              borderRadius="24px"
              overflow="hidden"
              onClick={() => trackEvent(WATCH_HOW_IT_WORKS)}
            >
              <iframe
                width="571"
                height="299"
                src={link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Show>
          <Show breakpoint="(max-width: 991px)">
            <Box
              mt="2rem"
              borderRadius="24px"
              overflow="hidden"
              onClick={() => trackEvent(WATCH_HOW_IT_WORKS)}
            >
              <iframe
                width="328"
                height="189"
                src={link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Show>
        </>
      )}
      {imgKey && getWorksIllustration[imgKey] ? (
        <Center>
          <Show above="lg">
            <Box mt="10px">
              <BaseImage
                src={getWorksIllustration[imgKey].web}
                alt="how-it-works"
                width={1192}
                height={806}
              />
            </Box>
          </Show>

          <Show below="lg">
            <Box mt="40px">
              <BaseImage
                src={getWorksIllustration[imgKey].mobile}
                alt="how-it-works"
                width={327}
                height={296}
              />
            </Box>
          </Show>
        </Center>
      ) : null}
      {worksData && worksData?.length > 0 && (
        <Center flexDirection="column">
          <SimpleGrid columns={{ base: 1, lg: 3 }} gap="24px" mt="2.5rem">
            {worksData.map((data: StrapiCard) => (
              <Flex
                flexDir="column"
                align="center"
                key={data.key}
                p={{ base: '16px 24px', lg: '24px 16px' }}
                minH={{ base: 'fit-content', lg: '304px' }}
                maxW={{ base: '310px', lg: '381px' }}
              >
                {data.img_key && (
                  <BaseImage
                    src={getWorksIllustration[data.img_key].web}
                    width={285}
                    height={214}
                    alt={data.key || data.img_key}
                  />
                )}

                <Text
                  fontSize={{ base: 16, xl: 24 }}
                  fontWeight="600"
                  mt="1.5rem"
                >
                  {data?.title}
                </Text>
                <Text
                  fontWeight="500"
                  fontSize={{ base: 12, xl: 14 }}
                  textAlign="center"
                  mt="4px"
                  color="textSecondary"
                  maxW={{ base: '280px', xl: '381px' }}
                >
                  {data?.description}
                </Text>
              </Flex>
            ))}
          </SimpleGrid>
          <Button
            variant="primary"
            as="a"
            href={sectionDetails.cta_link}
            mt="2.5rem"
            p="16px 45px"
            h="56px"
          >
            {sectionDetails.cta_text}
          </Button>
        </Center>
      )}
    </Section>
  );
};

export default How;
