import { ReactNode, createContext } from 'react';

import { DefiPageData } from '@/types/cms/defi-page.types';

interface IData {
  page: {
    // TODO add types for stake data
    data?: any;

    defiData?: DefiPageData | null;
    error?: any;
  };
  isSafeMode?: boolean;
}

const initialData: IData = {
  page: {
    data: null,
    error: null,
  },
  isSafeMode: false,
};

export const PageContext = createContext(initialData);

interface IProps extends IData {
  children: ReactNode;
}

export const PageProvider = ({
  page = initialData.page,
  isSafeMode = false,
  children,
}: IProps) => {
  return (
    <PageContext.Provider value={{ page, isSafeMode }}>
      {children}
    </PageContext.Provider>
  );
};

export { type IData as PageContextData };
