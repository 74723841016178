import React from 'react';

import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import dynamic from 'next/dynamic';

import { TextSecondary } from '@/themes';

import loader_dark from '../../assets/lottie-json/loader_dark.json';
import loader_light from '../../assets/lottie-json/loader_light.json';
import Icon from '../../components/Icon';
import { TransactionIconMapping } from '../../constants/constants';
import { TransactionType } from '../../types/common';
import FooterButtons from './components/FooterButtons';

const Lottie = dynamic(() => import('react-lottie'), { ssr: false });

interface SuccessErrorModalBodyTemplateProps {
  transactionType?: TransactionType;
  isSpinnerRequired?: boolean;
  modalTitle: string;
  modalSubTitle?: string;
  primaryBtnTxt?: string; // view transactions
  secondaryBtnTxt?: string; // close or cancel
  onSubmitPrimary?: () => void;
  onSubmitSecondary?: () => void;
}

const SuccessErrorModalBodyTemplate = ({
  isSpinnerRequired = false,
  transactionType,
  modalTitle,
  modalSubTitle,
  primaryBtnTxt,
  secondaryBtnTxt,
  onSubmitPrimary,
  onSubmitSecondary,
}: SuccessErrorModalBodyTemplateProps) => {
  const defaultOptions = {
    name: 'animantion',
    loop: true,
    autoplay: true,
    renderer: 'svg',
    animationData: useColorModeValue(loader_light, loader_dark),
  };

  return (
    <>
      {isSpinnerRequired && (
        <Box h="160px" w="160px">
          <Lottie options={defaultOptions} />
        </Box>
      )}
      {transactionType && (
        <Icon
          Icon={TransactionIconMapping[transactionType].icon}
          width="48px"
          height="48px"
        />
      )}
      <Text
        mt={isSpinnerRequired ? '1rem' : '2rem'}
        fontSize={{
          base: '20px',
          md: '24px',
        }}
        lineHeight="28px"
      >
        {modalTitle}
      </Text>
      {modalSubTitle && (
        <TextSecondary
          mt="0.5rem"
          fontSize={{
            base: '12px',
            md: '14px',
          }}
          fontWeight="400"
          textAlign="center"
        >
          {modalSubTitle}
        </TextSecondary>
      )}
      {primaryBtnTxt || secondaryBtnTxt ? (
        <Box mt="2rem">
          <FooterButtons
            secondaryBtnTxt={secondaryBtnTxt}
            onSubmitSecondary={onSubmitSecondary}
            primaryBtnTxt={primaryBtnTxt}
            onSubmitPrimary={onSubmitPrimary}
          />
        </Box>
      ) : null}
    </>
  );
};

export default SuccessErrorModalBodyTemplate;
