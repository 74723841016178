import React, { useEffect, useMemo } from 'react';

import { RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { AppProvider } from '@stader-labs/web-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { useDispatch } from 'react-redux';
import { WagmiProvider } from 'wagmi';

import TermsAndCondition from '@/components/TermsAndCondition';
import { wagmiConfig as getWagmiConfig } from '@/config/wagmi.config';
import { useChainData } from '@/hooks/useChainData';
import { useSetNetworkFromQP } from '@/hooks/useSetNetworkFromQP';
import { updateStakeData, updateWalletData } from '@/store';
import { getCurrentChain } from '@/utils/common';

import { getConfig } from '../config';
import { useFirebaseEvent } from '../hooks/useFirebaseEvent';
import { Token } from '../types/common';
import { getTokenForWebSDK } from '../utils/common';
import Services from './Services';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

export const SdkAppClientProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const { chainId } = useChainData();
  const pathname = usePathname();
  const token = getCurrentChain(pathname);

  useSetNetworkFromQP();

  return (
    <AppProvider
      token={getTokenForWebSDK(token as unknown as Token)}
      chainId={chainId}
    >
      {children}
      <Services />
    </AppProvider>
  );
};

export const WagmiClientProvider = ({ children }: any) => {
  const dispatch = useDispatch();

  useFirebaseEvent();

  const defaultTheme = lightTheme({
    accentColor: '#3F6870',
    fontStack: 'system',
    overlayBlur: 'small',
  });
  const pathname = usePathname();

  const token = getCurrentChain(pathname);

  const wagmiConfig = useMemo(() => getWagmiConfig(token), [token]);

  const config = getConfig(token);

  useEffect(() => {
    dispatch(
      updateStakeData({
        blockExplorerURL: config.blockExplorerUrls[0],
      }),
    );
    dispatch(
      updateWalletData({
        config: config,
      }),
    );
  }, [config]);

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          appInfo={{
            appName: 'Stader Labs',
            disclaimer: TermsAndCondition,
          }}
          theme={{
            ...defaultTheme,
            colors: {
              ...defaultTheme.colors,
              modalBackground: '#F5F5F7',
              modalText: '#3F6870',
            },
          }}
        >
          <SdkAppClientProvider>{children}</SdkAppClientProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
