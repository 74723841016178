import { useState } from 'react';

import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useWalletClient } from 'wagmi';

import { ContractTokenConfig } from '@/config/contracts.config';
import { trackEvent } from '@/dapp-sdk-v2';
import { useChainData } from '@/hooks/useChainData';

import { ADD_LIQUID_TOKEN } from '../../constants/firebase';
import AddXSDMessageModal from '../AddXSDMessageModal';

interface AddXSymbolProps {
  addXSymbolCTA?: string;
}

const AddXSymbol = ({ addXSymbolCTA }: AddXSymbolProps) => {
  const { contractConfig } = useChainData();
  const { data: signer } = useWalletClient();
  const { tokenAmount, tokenXAmount, tokenXSymbol, isEmbed, isSafeApp } =
    useSelector((state: any) => state.user);
  const [errorAddingXSD, setErrorAddingXSD] = useState('');
  const [shouldDisplayAddXSDModal, setShouldDisplayXSDModal] = useState(false);
  const [isTxnProcessing, setIsTxnProcessing] = useState(false);

  const isIframeEnv = isEmbed || isSafeApp;

  if (isIframeEnv) {
    return null;
  }

  const addXTokenToWallet = async () => {
    if (!signer || !contractConfig) {
      return;
    }

    trackEvent(ADD_LIQUID_TOKEN, {
      token_balance: tokenAmount,
      liquidtoken_balance: tokenXAmount,
    });

    setIsTxnProcessing(true);
    setShouldDisplayXSDModal(true);

    try {
      await signer?.watchAsset({
        type: 'ERC20',
        // ? this method takes address as string not as Hash
        options: contractConfig.xToken as ContractTokenConfig & {
          address: string;
        },
      });
    } catch (error) {
      const err = error as any;
      if (err instanceof Error || err?.details) {
        setErrorAddingXSD(err.details);
      } else {
        setErrorAddingXSD(`Error Adding ${tokenXSymbol}`);
      }
    } finally {
      setIsTxnProcessing(false);
    }
  };

  return (
    <>
      {addXSymbolCTA ? (
        <Button
          variant="outline"
          size="sm"
          border="1px"
          borderRadius="6px"
          borderColor="primary"
          background="backgroundSecondary"
          fontSize="10px"
          fontWeight="500"
          ml="8px"
          h="24px"
          p="4px 16px"
          onClick={addXTokenToWallet}
          color="primary"
          _dark={{
            color: 'textPrimary',
            border: 'none',
            bg: 'backgroundPrimary',
          }}
        >
          {addXSymbolCTA}
        </Button>
      ) : null}

      {shouldDisplayAddXSDModal && (
        <AddXSDMessageModal
          isOpen={shouldDisplayAddXSDModal}
          onCancel={() => {
            setShouldDisplayXSDModal(false);
            setErrorAddingXSD('');
          }}
          error={errorAddingXSD}
          isTxnProcessing={isTxnProcessing}
          token={contractConfig?.xToken.symbol ?? ''}
        />
      )}
    </>
  );
};

export default AddXSymbol;
