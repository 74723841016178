import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ContractTokenConfig } from '@/config/contracts.config';

interface StakePageState {
  stakeToken: ContractTokenConfig | null;
}

const initialState = {
  stakeToken: null,
} satisfies StakePageState as StakePageState;

export const stakePageSlice = createSlice({
  name: 'web3',
  initialState,
  reducers: {
    setStakeToken: (
      state,
      action: PayloadAction<ContractTokenConfig | null>,
    ) => {
      state.stakeToken = action.payload;
    },
  },
});

export const { setStakeToken } = stakePageSlice.actions;

export const stakePageReducer = stakePageSlice.reducer;
