import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useIdle } from 'react-use';
import { useBalance } from 'wagmi';

import { ContractTokenConfig } from '@/config/contracts.config';
import { useChainData } from '@/hooks/useChainData';
import { updateUserData, useAppSelector } from '@/store';

// Utils
import { toFixedWithoutRounding } from '../utils/common';

const useTokenBalance = () => {
  const stakeToken = useAppSelector(
    (state) => state.stakePage.stakeToken,
  ) as ContractTokenConfig | null;

  const { contractConfig } = useChainData();

  const isIdle = useIdle(150e3);
  const dispatch = useDispatch();
  const { walletAddress } = useSelector((state: any) => state.wallet);

  const { data: nativeTokenBalance } = useBalance({
    address: walletAddress,
    token: stakeToken?.address,
    query: {
      enabled: !isIdle && !!walletAddress && !!contractConfig,
      refetchInterval: 5_000,
      initialData: {
        decimals: stakeToken?.decimals ?? 18,
        formatted: '0',
        symbol: stakeToken?.symbol ?? '',
        value: 0n,
      },
    },
  });

  const { data: xTokenTokenBalance } = useBalance({
    address: walletAddress,
    token: contractConfig?.xToken.address,
    query: {
      enabled: !!walletAddress && !isIdle && !!contractConfig,
      refetchInterval: 5_000,
      initialData: {
        decimals: contractConfig?.xToken.decimals ?? 18,
        formatted: '0',
        symbol: contractConfig?.xToken.symbol ?? '',
        value: 0n,
      },
    },
  });

  useEffect(() => {
    dispatch(
      updateUserData({
        tokenAmount: +toFixedWithoutRounding(
          nativeTokenBalance?.formatted || '0',
          8,
        ),
        tokenSymbol: stakeToken?.symbol ?? '',
        tokenXAmount: +toFixedWithoutRounding(
          xTokenTokenBalance?.formatted || '0',
          8,
        ),
        tokenXSymbol: contractConfig?.xToken.symbol ?? '',
      }),
    );
  }, [nativeTokenBalance, xTokenTokenBalance]);

  return {
    tokenAmount: +toFixedWithoutRounding(
      nativeTokenBalance?.formatted || '0',
      8,
    ),
    tokenSymbol: stakeToken?.symbol ?? '',
    tokenXAmount: +toFixedWithoutRounding(
      xTokenTokenBalance?.formatted || '0',
      8,
    ),
    tokenXSymbol: contractConfig?.xToken.symbol ?? '',
  };
};

export default useTokenBalance;
